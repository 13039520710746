<template>
  <section id="content" style="margin-bottom: 80px">
    <v-card
      class="mx-auto py-1 mb-2"
      elevation="24"
      light
      max-width="95%"
      style="margin: -64px 70px 0px 0px"
      v-bind="$attrs"
      tile
      outlined
    >
      <v-sheet max-width="1000" class="mx-auto py-0 py-md-6">
        <v-container id="vineyards">
          <v-card-title
            :class="[$vuetify.breakpoint.mdAndUp ? 'display-2' : 'headline']"
            class="justify-center font-weight-bold grey--text text--darken-3 mb-3 text-center"
          >
            Walking and Cycling Paths
          </v-card-title>
          <v-card-text
            class="text-left text-center title font-weight-light mx-auto mb-5"
          >
            Twin Streams is host to some of the most amazing mountain biking,
            walking and hiking paths. Many of these are family and dog freindly.
            Along these paths the untouched beauty of mtunzini can be seen, with
            some of the richest variety of bird life in the area. There are a
            multitude of paths that start at the nursery and we would be happy
            to direct you.
          </v-card-text>

          <!-- <div class="text-center">
            <p>
              *Note: La Grotto Restuarant only serves Constantia Uitsig wine. Customers are not allowed to bring their own wine.
            </p>
          </div> -->
        </v-container>

        <v-card
          class="mx-auto"
          height="70vh"
          width="80vw"
          tile
          max-width="100%"
        >
          <v-carousel
            cycle
            height="70vh"
            width="80vw"
            hide-delimiters
            show-arrows-on-hover
          >
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="item.src"
            />
          </v-carousel>
        </v-card>

        <section class="mx-auto pt-5 pb-0 mb-2">
          <v-container>
            <v-row justify="center">
              <v-col
                v-for="([icon, info1, info2, href], i) in details"
                :key="i"
                cols="12"
                md="3"
              >
                <div class="text-center">
                  <a
                    style="text-decoration: none;"
                    :href="href"
                    target="_blank"
                    rel="noopener"
                  >
                    <v-icon
                      color="thirdary"
                      size="64"
                      class="mb-3"
                      v-text="icon"
                    />

                    <div color="thirdary" class="mb-2 title" v-text="info1" />
                    <div color="thirdary" v-text="info2" />
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-sheet>
      <v-sheet class="mx-auto py-0 mb-2" color="grey lighten-2">
        <p style="text-align: center;">
          <strong>Open</strong>
          <br />
          <em>Monday:</em> closed
          <br />
          <em>Tuesday – Friday:</em> 07:00-16:00
          <br />
          <em>Saturday & Sunday & Public Holidays:</em> 07:00-13:00
          <br />
        </p>
      </v-sheet>
    </v-card>
  </section>
</template>

<script>
export default {
  name: "SampleContents",

  data: () => ({
    items: [
      {
        src: require("@/assets/Main/walks/frog1.webp")
      },
      {
        src: require("@/assets/Main/walks/wally1.webp")
      },
      {
        src: require("@/assets/Main/walks/shoot.webp")
      },
      {
        src: require("@/assets/Main/walks/forest (1).webp")
      },
      {
        src: require("@/assets/Main/walks/forest (2).webp")
      },
      {
        src: require("@/assets/Main/walks/forest (3).webp")
      },
      {
        src: require("@/assets/Main/walks/forest (4).webp")
      },
      {
        src: require("@/assets/Main/walks/forest (5).webp")
      },
      {
        src: require("@/assets/Main/walks/forest (6).webp")
      },
      {
        src: require("@/assets/Main/walks/dog (1).webp")
      },
      {
        src: require("@/assets/Main/walks/dog (2).webp")
      },
      {
        src: require("@/assets/Main/walks/dragonfly.webp")
      }
    ],
    details: [
      [
        "mdi-crosshairs-gps",
        "Twinstreams Indigenous Nursery",
        "Mtunzini, KZN, 3867",
        "https://www.google.com/maps/place/Twinstreams+Indigenous+Nursery/@-28.9866208,31.725773,17z/data=!3m1!4b1!4m5!3m4!1s0x1efa0207765001cd:0xb1ef24fea994c6bf!8m2!3d-28.9866208!4d31.7279617"
      ],
      [
        "mdi-email",
        "info@twinstreams.co.za ",
        "",
        "mailto:info@twinstreams.co.za "
      ],
      ["mdi-phone", "074 189 6354", "", "tel:+27-74-189-6354"]
    ],
    socials: [
      [
        "https://www.facebook.com/blockhousekitchen.constantia/",
        "mdi-facebook",
        "Facebook"
      ],
      [
        "https://www.instagram.com/blockhousekitchen/",
        "mdi-instagram",
        "Instagram"
      ]
    ]
  })
};
</script>

<style scoped>
.v-card {
  border-radius: 0px;
}
</style>
